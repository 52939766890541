import { mgop } from '@aligov/jssdk-mgop'
import store from '@/store'

export const mgopRequest = (payload) => {
  let hasToken = store.getters['user/token']

  return new Promise((resolve, reject) => {
    // 仿照axios的传参方式
    const data = Object.assign(
      {},
      {
        api: payload.zwApi, // 必须,政务中台rpc接口的制定名字。例如‘mgop.xxx.app.login’
        host: 'https://mapi.zjzwfw.gov.cn/', // 固定为这个地址
        dataType: payload.dataType || 'json', // 目前官方只支持JSON格式
        data: payload.data || payload.params, // 不管入参是query还是body都同意用data传入，到时候在工作台里配置即可
        type: payload.method, // 和axios一样
        isBuffer: payload.isBuffer,
        appKey: 'rhkgehmp+2001943773+bizciy', // 必须，政务中台appKey, 系统申请之后大数据局发过来的excel里有
        // 成功后的回调
        onSuccess: (data) => {
          console.log(data.data)
          resolve(data.data)
        },
        // 请求失败后的回调
        onFail: (err) => {
          console.log(err, 'err')
          reject(err.message)
        },
      },
      // 是否需要传JWT令牌,对于登录和一些免登接口，payload.noToken = true
      payload.noToken ? {} : { header: { Authorization: 'Bearer ' + hasToken } }
    )
    mgop(data)
  })
}
