import {
  storage,
  tokenTableName,
  expires_in,
  userInfo,
  teamInfo,
} from '@/config/setting.config'

/**
 * @description 获取token
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(tokenTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(tokenTableName)
    } else {
      return localStorage.getItem(tokenTableName)
    }
  } else {
    return localStorage.getItem(tokenTableName)
  }
}

/**
 * @description 获取express_in
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getExpressIn() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(expires_in)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(expires_in)
    } else {
      return localStorage.getItem(expires_in)
    }
  } else {
    return localStorage.getItem(expires_in)
  }
}

/**
 * @description 获取用户信息
 * XMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getUserInfo() {
  console.log(sessionStorage.getItem(userInfo))
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(userInfo)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(userInfo)
    } else {
      return localStorage.getItem(userInfo)
    }
  } else {
    return localStorage.getItem(userInfo)
  }
}

/**
 * @description 获取用户信息
 * XMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getTeamInfo() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(teamInfo)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(teamInfo)
    } else {
      return localStorage.getItem(teamInfo)
    }
  } else {
    return localStorage.getItem(teamInfo)
  }
}

/**
 * @description 存储token
 * @param token
 * @returns {void|*}
 */
export function setToken(token) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(tokenTableName, token)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(tokenTableName, token)
    } else {
      return localStorage.setItem(tokenTableName, token)
    }
  } else {
    return localStorage.setItem(tokenTableName, token)
  }
}

/**
 * @description 存储过期时间
 * @param express_in
 * @returns {void|*}
 */
export function setExpressIn(express_in) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(expires_in, express_in)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(expires_in, express_in)
    } else {
      return localStorage.setItem(expires_in, express_in)
    }
  } else {
    return localStorage.setItem(expires_in, express_in)
  }
}

/**
 * @description 存储用户信息
 * @param Info
 * @returns {void|*}
 */
export function setUserInfo(Info) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(userInfo, Info)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(userInfo, Info)
    } else {
      return localStorage.setItem(userInfo, Info)
    }
  } else {
    return localStorage.setItem(userInfo, Info)
  }
}

/**
 * @description 存储用户信息
 * @param Info
 * @returns {void|*}
 */
export function setTeamInfo(Info) {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(teamInfo, Info)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(teamInfo, Info)
    } else {
      return localStorage.setItem(teamInfo, Info)
    }
  } else {
    return localStorage.setItem(teamInfo, Info)
  }
}

/**
 * @description 移除token
 * @returns {void|Promise<void>}
 */
export function removeToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(tokenTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem(tokenTableName)
    }
  } else {
    return localStorage.removeItem(tokenTableName)
  }
}

/**
 * @description 移除expires_in
 * @returns {void|Promise<void>}
 */
export function removeExpiresIn() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(expires_in)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem(expires_in)
    }
  } else {
    return localStorage.removeItem(expires_in)
  }
}

/**
 * @description 移除用户信息
 * @returns {void|Promise<void>}
 */
export function removeUserInfo() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(userInfo)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem(userInfo)
    }
  } else {
    return localStorage.removeItem(userInfo)
  }
}
