/**
 * @description 导出通用配置
 */
module.exports = {
  //请求环节
  requestEnv: 'axios', //axios || aligov
  tokenName: 'Authorization',
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'token',
  // token的有效时间
  expires_in: 'expires_in',
  // 用户信息
  userInfo: 'userInfo',
  // 中队信息
  teamInfo: 'teamInfo',
  // token存储位置localStorage sessionStorage cookie
  storage: 'localStorage',
  // 是否开启登录RSA加密
  loginRSA: true,
  // 加密公钥
  publicKey:
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC0RfctMZofmzbXOUzHJHmNsmdi\n' +
    'K99v3ZwoNTt0VH7hpQYt78ccVoTC4F233w5tjro9dIhaq8d76G8U+8MWWTDC7J+i\n' +
    'ZZs/yBy7vZcVC+T6lN94hwX2QS6y0zNleN2dWNfPAdNVwxDMtQqGXJEwAAmvrsr/\n' +
    'o0liO3stx4aTlAVfjwIDAQAB',
  fileType:
    '.doc, .docx, .xls, .xlsx, .pdf, image/*, .rar, zip, .7z, ppt, pptx, .txt',
  //浙政钉APPID
  appID: '21938',
  //浙政钉APPName
  appName: 'MQSB',
  //JSAPI
  jsApiList: ['getGeolocation', 'startGeolocation', 'stopGeolocation'],
  //系统地址，用于图片地址拼接
  systemUrl: 'http://115.231.59.75:80',
  //systemUrl: 'http://113.31.115.78:8039',
  //systemUrl: 'http://localhost:3231',
  //期望定位精度半径(单位米)
  targetAccuracy: 100,
  // iOS端位置变更敏感度，单位为m，此值会影响iOS端callback回调速率
  iOSDistanceFilter: 100,
  // 数据回传最小时间间隔，单位ms
  callBackInterval: 2500,
}
