/**
 * @description 导入所有 vuex 模块，自动加入namespaced:true，用于解决vuex命名冲突，请勿修改。
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const files = require.context('./modules', false, /\.js$/)
const modules = {}
files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
Object.keys(modules).forEach((key) => {
  modules[key]['namespaced'] = true
})
const store = new Vuex.Store({
  modules,
})
export default store

/*
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  //定义了应用状态的数据结构，可以在这里设置默认的初始状态。
  state: {
    UserInfo:{},
    token: '',
  },
  //是唯一更改 store 中状态的方法，且必须是同步函数。
  mutations: {
    setUserInfo(state, data) {
      state.UserInfo = data;
      sessionStorage.setItem("UserInfo", data)
    },
    setToken(state, data) {
      state.token = data;
      sessionStorage.setItem("token", data)
    },
  },
  //用于提交 mutation，而不是直接变更状态，可以包含任意异步操作。
  actions: {
    setUserInfo({ commit }, data) {
      commit('setUserInfo', data);
    },
    setToken({ commit }, data) {
      commit('setToken', data);
    },
  },
  //可以将 store 分割成模块（module）。每个模块拥有自己的 state、mutation、action、getter、甚至是嵌套子模块——从上至下进行同样方式的分割
  modules: {
  },
  getters: {
    token: (state) => state.token,
    userInfo: (state) => state.userInfo,
  }
})
*/
