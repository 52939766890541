import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)
const routes = [
  /**
   * @description 基础路由
   */
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index'),
  },
  /**
   * @description 待办任务路由
   */
  //代办任务首页
  {
    path: '/istIndex',
    name: 'Ist',
    component: () => import('@/views/ist/index'),
  },
  //代办任务列表
  {
    path: '/ist',
    name: 'Ist',
    component: () => import('@/views/ist/ist/index'),
  },
  //行政审批
  {
    path: '/istAdministrationIssued',
    name: 'IstAdministrationIssued',
    component: () =>
      import('@/views/ist/ist/components/istAdministrationIssued'),
  },
  {
    path: '/istAdministrationCell',
    name: 'IstAdministrationCell',
    component: () => import('@/views/ist/ist/components/istAdministrationCell'),
  },
  {
    path: '/approve',
    name: 'Approve',
    component: () => import('@/views/workBench/approve/index'),
  },
  {
    path: '/approveInfo',
    name: 'ApproveInfo',
    component: () => import('@/views/workBench/approve/components/approveInfo'),
  },

  //综治办事件流程
  {
    path: '/istEventIssued',
    name: 'IstEventIssued',
    component: () => import('@/views/ist/ist/components/istEventIssued'),
  },
  {
    path: '/istEventCell',
    name: 'IstEventCell',
    component: () => import('@/views/ist/ist/components/istEventCell'),
  },
  //店铺赋色流程
  {
    path: '/istShopColorationIssued',
    name: 'IstShopColorationIssued',
    component: () =>
      import('@/views/ist/ist/components/istShopColorationIssued'),
  },
  {
    path: '/istShopColorationCell',
    name: 'IstShopColorationCell',
    component: () => import('@/views/ist/ist/components/istShopColorationCell'),
  },
  //每日网格考勤
  {
    path: '/cellAttendance',
    name: 'CellAttendance',
    component: () => import('@/views/ist/cellAttendance/index'),
  },
  //每周评分任务
  {
    path: '/cellTask',
    name: 'CellTask',
    component: () => import('@/views/ist/cellTask/index'),
  },
  //中队夜间考勤
  {
    path: '/temAttendance',
    name: 'TemAttendance',
    component: () => import('@/views/ist/temAttendance/index'),
  },
  /**
   * @description 工作台路由
   */
  //店铺信息
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/views/workBench/shop/index'),
  },
  {
    path: '/shopAdd',
    name: 'ShopAdd',
    component: () => import('@/views/workBench/shop/components/shopAdd'),
  },
  {
    path: '/shopInfo',
    name: 'ShopInfo',
    component: () => import('@/views/workBench/shop/components/shopInfo'),
  },
  {
    path: '/shopColoration',
    name: 'ShopColoration',
    component: () => import('@/views/workBench/shop/components/shopColoration'),
  },

  //劝导
  {
    path: '/advise',
    name: 'Advise',
    component: () => import('@/views/workBench/advise/index'),
  },
  {
    path: '/adviseAdd',
    name: 'AdviseAdd',
    component: () => import('@/views/workBench/advise/components/adviseAdd'),
  },
  {
    path: '/adviseInfo',
    name: 'AdviseInfo',
    component: () => import('@/views/workBench/advise/components/adviseInfo'),
  },

  //商家投诉
  {
    path: '/shopComplain',
    name: 'ShopComplain',
    component: () => import('@/views/workBench/shopComplain/index'),
  },
  {
    path: '/shopComplainInfo',
    name: 'ShopComplainInfo',
    component: () =>
      import('@/views/workBench/shopComplain/components/shopComplainInfo'),
  },
  //用户投诉
  {
    path: '/userComplain',
    name: 'UserComplain',
    component: () => import('@/views/workBench/userComplain/index'),
  },
  {
    path: '/userComplainInfo',
    name: 'UserComplainInfo',
    component: () =>
      import('@/views/workBench/userComplain/components/userComplainInfo'),
  },
  {
    path: '/userComplainApparitorList',
    name: 'UserComplainApparitorList',
    component: () =>
      import(
        '@/views/workBench/userComplain/components/userComplainApparitorList'
      ),
  },
  {
    path: '/userComplainApparitor',
    name: 'UserComplainApparitor',
    component: () =>
      import('@/views/workBench/userComplain/components/userComplainApparitor'),
  },
  /**
   * @description 门前三包路由
   */
  //门前三包
  {
    path: '/scan',
    name: 'Scan',
    component: () => import('@/views/outDoor/scan/index'),
  },
  //绑定店铺
  {
    path: '/bindShop',
    name: 'BindShop',
    component: () => import('@/views/outDoor/scan/components/bindShop'),
  },
  //店铺走访
  {
    path: '/shopView',
    name: 'ShopView',
    component: () => import('@/views/outDoor/shopView/index'),
  },
  {
    path: '/addView',
    name: 'AddView',
    component: () => import('@/views/outDoor/shopView/components/addView'),
  },
  {
    path: '/viewInfo',
    name: 'ViewInfo',
    component: () => import('@/views/outDoor/shopView/components/viewInfo'),
  },
  //地图测试
  {
    path: '/baiduMap',
    name: 'BaiduMap',
    component: () => import('@/views/baiduMap/index.vue'),
  },
  //扫码考勤
  {
    path: '/attendance',
    name: 'Attendance',
    component: () => import('@/views/workBench/attendance/index.vue'),
  },
  //重点店铺设置
  {
    path: '/shopQR',
    name: 'ShopQR',
    component: () => import('@/views/outDoor/shopQR/index.vue'),
  },
  //夜间中队店铺设置
  {
    path: '/teamQR',
    name: 'TeamQR',
    component: () => import('@/views/outDoor/teamQR/index.vue'),
  },
  //通知公告
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('@/views/workBench/notice/index.vue'),
  },
  {
    path: '/noticeInfo',
    name: 'NoticeInfo',
    component: () => import('@/views/workBench/notice/components/noticeInfo'),
  },
  //平台事件
  {
    path: '/event',
    name: 'Event',
    component: () => import('@/views/workBench/event/index'),
  },
  {
    path: '/eventInfo',
    name: 'EventInfo',
    component: () => import('@/views/workBench/event/components/eventInfo'),
  },
  //办案列表
  {
    path: '/case',
    name: 'Case',
    component: () => import('@/views/outDoor/case/index'),
  },
  {
    path: '/caseInfo',
    name: 'CaseInfo',
    component: () => import('@/views/outDoor/case/components/caseInfo'),
  },
  {
    path: '/caseAdd',
    name: 'CaseAdd',
    component: () => import('@/views/outDoor/case/components/caseAdd'),
  },
  //店铺评分
  {
    path: '/shopGrade',
    name: 'ShopGrade',
    component: () => import('@/views/outDoor/shopGrade/index'),
  },
  {
    path: '/shopGradeInfo',
    name: 'ShopGradeInfo',
    component: () =>
      import('@/views/outDoor/shopGrade/components/shopGradeInfo'),
  },
  {
    path: '/shopGradeAdd',
    name: 'ShopGradeAdd',
    component: () =>
      import('@/views/outDoor/shopGrade/components/shopGradeAdd'),
  },
  //城管图表
  {
    path: '/echarts',
    name: 'Echarts',
    component: () => import('@/views/echarts/index'),
  },
  {
    path: '/outDoorEcharts',
    name: 'OutDoorEcharts',
    component: () => import('@/views/echarts/outDoor/index'),
  },
  {
    path: '/teamTargetEcharts',
    name: 'TeamTargetEcharts',
    component: () => import('@/views/echarts/teamTarget/index'),
  },
  {
    path: '/teamPhotoEcharts',
    name: 'TeamPhotoEcharts',
    component: () => import('@/views/echarts/teamPhoto/index'),
  },
  {
    path: '/green',
    name: 'JKDZ',
    component: () => import('@/views/JKDZ'),
  },
]

const router = new VueRouter({
  routes,
})

/*//路由导航守卫
router.beforeEach(async (to, from, next) => {
  // if (to.path === '/login') return next()
  // let hasToken = store.getters['user/token']
  // if (!hasToken) {
  //   return next('/login')
  // }
  // await store.dispatch('user/getUserInfo')
  // next()
})*/

export default router
