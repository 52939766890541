import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import {
  baseURL,
  contentType,
  messageName,
  requestTimeout,
  statusName,
  successCode,
} from '@/config/net.config'
import { tokenName, requestEnv } from '@/config/setting.config'
import { isArray } from '@/util/validate'
import { setExpressIn, setToken } from '@/util/token'
import { mgopRequest } from '@/util/mgop'
// 操作正常Code数组
const codeVerificationArray = isArray(successCode)
  ? [...successCode]
  : [...[successCode]]

const handleData = ({ config, data, status, statusText }) => {
  if (data[statusName] === 0) data[statusName] = '0'
  // 若data.code存在，覆盖默认code
  let code = data && data[statusName] ? data[statusName] : status
  // 若code属于操作正常code，则status修改为200
  if (codeVerificationArray.includes(code)) code = 200
  switch (code) {
    case 200:
      return data
    case 401:
      router.push({ path: '/', replace: true })
  }
  return Promise.reject()
}

/**
 * @description axios初始化
 */

const instance = axios.create({
  baseURL: baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
})

instance.interceptors.request.use(
  (config) => {
    const token = store.getters['user/token']
    if (token) config.headers[tokenName] = token
    return config
  },
  (err) => {}
)

instance.interceptors.response.use(
  (response) => {
    return handleData(response)
  },
  (error) => {
    const { response } = error
    if (response === undefined) {
      return {}
    } else return handleData(response)
  }
)
const isMgop = requestEnv === 'aligov' // 是否为浙里办版面
const request = isMgop ? mgopRequest : instance
export default request
